import { _compact, _values } from 'common/Utils';
import { AnnotationShapeType, AnnotationTool, DefaultToolMap, IAnnotation } from 'pages/AnnotationCS/entities';
import { cornerstone, cornerstoneTools } from '../CornerstoneInitHelper/CornerstoneHelper';
import { SegmentationUtils } from './SegmentationUtils';
import {
    CornerstonePointFactory,
    CornerstoneTextboxFactory,
    DicomViewerControlTool,
    IAngleMeasurementData,
    IBidirectionalMeasurementData,
    ICobbAngleMeasurementData,
    ILengthMeasurementData,
    IMeasurementData,
    IPoint,
    IPolygonMeasurementData,
    IRectangleRoiMeasurementData,
    StackState,
    ToolState,
} from './interface';
import { getPolygonBoundingBox } from './utils';

export abstract class CornerstoneToolsUtils extends SegmentationUtils {
    getViewportImage(index: number = this.activeElementIndex) {
        return this.imageMap.get(index);
    }

    getStackState(element: HTMLElement): { data: Array<StackState> } {
        return cornerstoneTools.getToolState(element, 'stack');
    }

    findImageIndex(imageId: string, viewportIndex = this.activeElementIndex) {
        const element = this.getViewportElement(viewportIndex);
        const stackState = this.getStackState(element);
        return stackState.data[0].imageIds.findIndex((url: string) => CornerstoneToolsUtils.getImageIdFromUrl(url) === imageId);
    }

    findImageId(index: number, viewport: number | HTMLElement = this.activeElementIndex) {
        const element = typeof viewport === 'number' ? this.getViewportElement(viewport) : viewport;
        return this.getStackState(element).data[0].imageIds[index];
    }

    getMeasurementToolData<T extends AnnotationTool>(tool: T): IMeasurementData<any>[] {
        const toolState: ToolState = this.globalToolStateManager?.toolState;

        return _compact(
            _values(toolState)
                ?.map(data => data?.[tool]?.data)
                ?.flat()
        );
    }

    get getPixelSpacing(): (image: any) => { rowPixelSpacing: number; columnPixelSpacing: number } {
        return cornerstoneTools.importInternal('util/getPixelSpacing');
    }

    transformPolygonToMeasurementData(object: IAnnotation, viewportIndex: number): IPolygonMeasurementData {
        const image = this.getViewportImage(viewportIndex);
        if (!image) return;
        const { calculateFreehandStatistics, FreehandHandleData, freehandArea } =
            cornerstoneTools.importInternal('util/freehandUtils');

        const points = object.points.map(p => new FreehandHandleData(p));

        const polyBoundingBox = getPolygonBoundingBox(points);
        const { rowPixelSpacing = 1, columnPixelSpacing = 1 } = this.getPixelSpacing(image);
        const area = freehandArea(points, rowPixelSpacing * columnPixelSpacing);
        const pixelData = cornerstone.getPixels(
            this.getViewportElement(viewportIndex),
            polyBoundingBox.left,
            polyBoundingBox.top,
            polyBoundingBox.width,
            polyBoundingBox.height
        );

        return {
            ...this.getCommonKeys(object),
            area,
            handles: {
                points,
                textBox: CornerstoneTextboxFactory(),
                invalidHandlePlacement: false,
            },
            meanStdDev: calculateFreehandStatistics(pixelData, polyBoundingBox, points),
            polyBoundingBox,
        };
    }

    transformAngleToMeasurementData(object: IAnnotation): IAngleMeasurementData | ICobbAngleMeasurementData {
        return {
            ...this.getCommonKeys(object),
            complete: true,
            rAngle: object.value,
            handles: object.type === 'angle' ? this.getAngleHandles(object) : this.getCobbAngleHandles(object),
        };
    }

    transformLengthToMeasurementData(object: IAnnotation): ILengthMeasurementData {
        return {
            ...this.getCommonKeys(object),
            length: object.value,
            handles: {
                start: CornerstonePointFactory(object.points[0]),
                end: CornerstonePointFactory(object.points[1]),
                textBox: CornerstoneTextboxFactory(),
            },
        };
    }

    transformBidirectionalToMeasurementData(object: IAnnotation): IBidirectionalMeasurementData {
        return {
            ...this.getCommonKeys(object),
            longestDiameter: object.value?.L,
            shortestDiameter: object.value?.W,
            handles: {
                start: CornerstonePointFactory(object.points[0]),
                end: CornerstonePointFactory(object.points[1]),
                perpendicularStart: CornerstonePointFactory(object.points[2]),
                perpendicularEnd: CornerstonePointFactory(object.points[3]),
                textBox: CornerstoneTextboxFactory(),
            },
        };
    }

    transformRectangleToMeasurementData(object: IAnnotation): IRectangleRoiMeasurementData {
        return {
            ...this.getCommonKeys(object),
            handles: {
                start: CornerstonePointFactory(object.points[0]),
                end: CornerstonePointFactory(object.points[1]),
                textBox: CornerstoneTextboxFactory(),
            },
        };
    }

    transformMaskToMeasurementData(object: IAnnotation): any {
        return object;
    }

    getCommonKeys(object: IAnnotation): Omit<IMeasurementData<any>, 'handles' | 'area'> {
        return {
            uuid: object.id,
            visible: true,
            active: false,
            invalidated: true,
            color: this.colorMap?.[object.label as string | number],
            type: DefaultToolMap[object.type as AnnotationShapeType],
            imageId: object.image_id,
            unit: object.unit,
            measurement_info: object.measurement_info,
            imageUrl: object.imageUrl,
        };
    }

    getAngleHandles(object: IAnnotation): any {
        return {
            start: CornerstonePointFactory(object.points[0]),
            middle: CornerstonePointFactory(object.points[1]),
            end: CornerstonePointFactory(object.points[2]),
            textBox: CornerstoneTextboxFactory(),
        };
    }

    getCobbAngleHandles(object: IAnnotation): any {
        return {
            start: CornerstonePointFactory(object.points[0]),
            end: CornerstonePointFactory(object.points[1]),
            start2: CornerstonePointFactory(object.points[2]),
            end2: CornerstonePointFactory(object.points[3]),
            textBox: CornerstoneTextboxFactory(),
        };
    }

    getRectArea(start: IPoint, end: IPoint) {
        const image = this.getViewportImage();

        const { rowPixelSpacing = 1, columnPixelSpacing = 1 } = this.getPixelSpacing(image);

        const width = Math.abs(start.x - end.x) * rowPixelSpacing;
        const height = Math.abs(start.y - end.y) * columnPixelSpacing;

        return width * height;
    }

    get globalToolStateManager() {
        return cornerstoneTools.globalImageIdSpecificToolStateManager;
    }

    get globalConfiguration() {
        return cornerstoneTools.getModule('globalConfiguration');
    }

    addImageIdToolState(imageId: string, toolName: AnnotationTool, data: IMeasurementData<any>) {
        // If we don't have any tool state for this imageId, add an empty object
        const toolState = this.globalToolStateManager.toolState;
        if (toolState.hasOwnProperty(imageId) === false) {
            toolState[imageId] = {};
        }

        const imageIdToolState = toolState[imageId];

        // If we don't have tool state for this tool name, add an empty object
        if (imageIdToolState.hasOwnProperty(toolName) === false) {
            imageIdToolState[toolName] = {
                data: [],
            };
        }

        const toolData = imageIdToolState[toolName];

        // Finally, add this new tool to the state
        toolData.data.push(data);
    }

    getStackToolData(viewportIndex = this.activeElementIndex) {
        const element = this.getViewportElement(viewportIndex);
        return cornerstoneTools.getToolState(element, 'stack')?.data?.[0];
    }

    getCurrentImageURL(viewportIndex = this.activeElementIndex) {
        if (!this.isElementEnabled(viewportIndex)) return;

        const element = this.getViewportElement(viewportIndex);
        const stack = cornerstoneTools.getToolState(element, 'stack')?.data?.[0];
        return stack?.imageIds[stack.currentImageIdIndex];
    }

    protected referenceLinesEnabled: boolean = false;
    protected referenceLineStateMap = new Map<string, boolean>();
    toggleReferenceLines() {
        this.elementMap.forEach(element => {
            if (this.referenceLinesEnabled) this.disableReferenceLines(element);
            else this.activateReferenceLines(element);
        });

        this.referenceLinesEnabled = !this.referenceLinesEnabled;

        this.forceRender();
    }

    RefereLines = new cornerstoneTools.Synchronizer('cornerstonenewimage', cornerstoneTools.updateImageSynchronizer);

    disableReferenceLines(element: HTMLElement) {
        this.RefereLines?.remove(element);
        cornerstoneTools.clearToolState(element, 'referenceLines');
        cornerstoneTools.setToolDisabledForElement(element, 'ReferenceLines', {
            synchronizationContext: this.RefereLines,
        });
        this.referenceLineStateMap.set(element.dataset.seriesId, false);
    }

    activateReferenceLines(element: HTMLElement) {
        this.RefereLines?.add(element);
        cornerstoneTools.setToolEnabledForElement(element, 'ReferenceLines', {
            synchronizationContext: this.RefereLines,
        });
        cornerstoneTools.addToolState(element, 'referenceLines', {
            Active: true,
        });
        this.referenceLineStateMap.set(element.dataset.seriesId, true);
    }

    resetReferenceLines() {
        this.referenceLinesEnabled = false;

        try {
            this.RefereLines?.destroy?.();
        } catch (error) {}

        this.RefereLines = new cornerstoneTools.Synchronizer('cornerstonenewimage', cornerstoneTools.updateImageSynchronizer);
    }

    defaultConfiguration: Partial<Record<DicomViewerControlTool, Dictionary>> = {
        BrushEraser: {
            alwaysEraseOnClick: true,
        },
        ThresholdsBrushEraser: {
            alwaysEraseOnClick: true,
        },
    };
}

import { _compact, _entries, _isEmpty, _keys, _uuid, _values, sleep } from 'common/Utils';
import { Image } from 'cornerstone-core';
import {
    AnnotationShapeType,
    AnnotationTool,
    AnnotationToolShapeMap,
    BrushToolList,
    DefaultToolMap,
    IAnnotation,
} from 'pages/AnnotationCS/entities';
import {
    activeMeasurementColor,
    cornerstone,
    cornerstoneTools,
    defaultMeasurementColor,
} from '../CornerstoneInitHelper/CornerstoneHelper';
import { ToolMap } from '../CustomTools';
import AssistedSliceSelectionCrossTool from '../CustomTools/AssistedSliceSelectionCrossTool';
import { CornerstoneToolsUtils } from './CornerstoneToolsUtils';
import { DicomViewerCore } from './DicomViewerCore';
import {
    BrushToolConfig,
    DicomViewerControlTool,
    IApplyLabelMap2DMask,
    IApplyMaskAnnotationsArgs,
    IApplyMeasurementsArgs,
    IEnableAnnotationToolArgs,
    IMeasurementData,
    IPoint,
    IRectangleRoiMeasurementData,
    LabelMaps3DReturnType,
    SavedSegmentation,
    ThresholdToolConfig,
} from './interface';

const deepmerge = cornerstoneTools.importInternal('util/deepmerge');

export class AnnotationManagement extends CornerstoneToolsUtils {
    private activeSegmentIndex: number;

    constructor() {
        super();

        this.applyMeasurements = this.applyMeasurements.bind(this);
        this.forceRender = this.forceRender.bind(this);
        this.transformPolygonToMeasurementData = this.transformPolygonToMeasurementData.bind(this);
        this.activateToolforAnnotation = this.activateToolforAnnotation.bind(this);
    }

    public pendingAnnotations: Set<string> = new Set();
    applyMeasurements(args: IApplyMeasurementsArgs, functionId = _uuid()) {
        const addToStash = () =>
            this.elementWaitedMethods.set(functionId, {
                name: 'applyMeasurements',
                args: [args, functionId],
            });

        const { measurementMap, viewportIndex } = args;
        if (_isEmpty(viewportIndex)) {
            if (!this.elementMap.size) return addToStash();

            if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);

            this.elementMap.forEach((_, key) => this.applyMeasurements({ measurementMap, viewportIndex: key }));
            return;
        }

        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(viewportIndex)) return addToStash();

        const initialTool = this.activeTool;
        _entries(measurementMap).forEach(([type, measurements]) => {
            const toolName = DefaultToolMap[type as AnnotationShapeType];
            const _tool = cornerstoneTools.getToolForElement(element, toolName);
            if (!_tool) this.addTool(toolName);

            this.enableTool(toolName);

            measurements?.forEach(m => {
                const measurementData = this.getMeasurementData(m, viewportIndex);
                if (!measurementData) return;

                if (this.pendingAnnotations.has(m.id)) return;

                this.addMeasurementToCornerstone(toolName, measurementData);
            });
        });

        this.enableTool(initialTool);
        this.forceRender(viewportIndex);

        if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);
    }

    removeAnnotation(annotationId: string, viewportIndex = this.activeElementIndex) {
        const viewport = this.getViewportElement(viewportIndex);
        const toolStateManager = this.globalToolStateManager;

        _keys(AnnotationToolShapeMap).forEach(tool => {
            const currentData = this.getMeasurementToolData(tool);

            currentData
                ?.filter((data: any) => annotationId === data.uuid)
                ?.forEach(data => {
                    cornerstoneTools.removeToolState(viewport, tool, data);
                    _values(toolStateManager?.toolState).forEach(state => {
                        if (!state[tool]?.data) return;
                        state[tool].data = state[tool]?.data?.filter((a: IMeasurementData<any>) => a?.uuid !== annotationId);
                    });
                    this.forceRender(viewportIndex);
                });
        });
    }

    public changeMeasurementVisibilty(id: string, tool: AnnotationTool, visible: boolean) {
        const toolState = this.getMeasurementToolData(tool);

        const toolData = toolState?.find(item => item.uuid === id);
        if (toolData) toolData.visible = visible;

        this.forceRender();
    }

    addMeasurementToCornerstone(tool: AnnotationTool, data: IMeasurementData<any>) {
        const currentData = this.getMeasurementToolData(tool);
        if (currentData?.some((a: any) => a.uuid === data.uuid)) return;

        const imageId = data?.imageUrl || DicomViewerCore.getImageUrl(this.dataset, { imageId: data.imageId });

        this.globalToolStateManager.addImageIdToolState(imageId, tool, data);
    }

    getMeasurementObjectById(id: string, tool: AnnotationTool) {
        const toolState = this.getMeasurementToolData(tool);
        return toolState?.find(a => a.uuid === id);
    }

    selectedAnnotation: IAnnotation;
    changeSelectedMeasurement(anno: IAnnotation) {
        if (this.selectedAnnotation) {
            this.changeMeasurementColor(
                DefaultToolMap[this.selectedAnnotation.type as AnnotationShapeType],
                this.selectedAnnotation.id,
                defaultMeasurementColor
            );
        }

        this.selectedAnnotation = anno;
        this.changeMeasurementColor(DefaultToolMap[anno.type as AnnotationShapeType], anno.id, activeMeasurementColor);
    }

    changeMeasurementColor(
        tool: AnnotationTool,
        id: any,
        color: `rgb(${number},${number},${number})`,
        viewportIndex = this.activeElementIndex
    ) {
        const currentData = this.getMeasurementToolData(tool);
        const data = currentData?.find((a: any) => a.uuid === id);

        if (!data) return;

        data.color = color;
        this.forceRender(viewportIndex);
    }

    async onImageRendered(image: Image, viewportIndex: number) {
        if (!this.ready) this.onReady();

        const element = this.getViewportElement(viewportIndex);
        this?.imageMap.set(viewportIndex, image);

        await sleep(200);
        this.applyElementWaitedMethods();

        if (this.referenceLinesEnabled && !this.referenceLineStateMap.get(element?.dataset?.seriesId)) {
            this.activateReferenceLines(element);
        }
    }

    getMeasurementData(object: IAnnotation, viewportIndex?: number) {
        switch (object.type) {
            case 'cobbAngle':
            case 'angle':
                return this.transformAngleToMeasurementData(object);

            case 'length':
                return this.transformLengthToMeasurementData(object);

            case 'polygon':
                return this.transformPolygonToMeasurementData(object, viewportIndex);

            case 'bidirectional':
                return this.transformBidirectionalToMeasurementData(object);

            case 'rect':
                return this.transformRectangleToMeasurementData(object);

            default:
                break;
        }
    }

    enableTool(toolName: DicomViewerControlTool = null, mouseButtonNumber: number = 1) {
        if (!cornerstoneTools) return;
        let activeDefaultTools = false;
        if (this.activeTool === 'Zoom' || this.activeTool === 'Pan') activeDefaultTools = true;
        this.activeTool = toolName;

        if (!toolName) return;
        cornerstoneTools.setToolActive(toolName, {
            mouseButtonMask: mouseButtonNumber,
        });
        if (activeDefaultTools) this.enableDefaultTools();
    }

    enableDefaultTools() {
        cornerstoneTools.setToolActive('Zoom', {
            mouseButtonMask: 2,
        });
        cornerstoneTools.setToolActive('Pan', {
            mouseButtonMask: 4,
        });
    }

    activateTools() {
        const initialTool = this.activeTool;
        _values(this.globalToolStateManager.toolState)
            .map(tools => _keys(tools))
            .flat()
            .forEach((tool: DicomViewerControlTool) => {
                this.enableTool(tool);
            });

        this.enableTool(initialTool);
    }

    enableToolforAnnotation(args: IEnableAnnotationToolArgs, functionId = _uuid()): any {
        const { label, configuration = {}, segmentOpacity = 255, toolName, viewportIndex } = args;
        if (!toolName) return;

        if (_isEmpty(viewportIndex))
            return this.elementMap.forEach((_, key) => this.enableToolforAnnotation({ ...args, viewportIndex: key }));

        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element)) {
            return this.elementWaitedMethods.set(functionId, {
                name: 'enableToolforAnnotation',
                args: [args, functionId],
            });
        }

        const _configuration = deepmerge(this.defaultConfiguration[toolName] || {}, configuration);

        let activeDefaultTools = false;
        if (this.activeTool === 'Zoom' || this.activeTool === 'Pan') activeDefaultTools = true;
        cornerstoneTools?.setToolPassive(this.activeTool, null);
        this.activeTool = toolName;

        let _tool = cornerstoneTools.getToolForElement(element, toolName);
        if (!_tool) {
            this.addTool(toolName, _configuration);
            _tool = cornerstoneTools.getToolForElement(element, toolName);
        } else {
            _tool.configuration = deepmerge(_tool.configuration, _configuration);
        }

        cornerstoneTools?.setToolActive(toolName, {
            mouseButtonMask: 1,
        });
        if (activeDefaultTools) this.enableDefaultTools();

        if (BrushToolList.includes(toolName as any)) {
            this.currentSegmentLabel = label;
            this.activateSegmentForSelectedCell(label, segmentOpacity, viewportIndex);
        }

        if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);
    }

    addTool(toolName: DicomViewerControlTool, configuration: any = {}) {
        const tool = (ToolMap as any)[toolName];
        if (!tool) return;

        cornerstoneTools?.addTool(tool, {
            name: toolName,
            configuration: {
                ...configuration,
            },
        });
    }

    lockAnnotationTools() {
        this.globalConfiguration.configuration.lockAnnotationTools = true;
    }

    unlockAnnotationTools() {
        this.globalConfiguration.configuration.lockAnnotationTools = false;
    }

    activateToolforAnnotation(toolName: DicomViewerControlTool = null) {
        switch (toolName) {
            case 'Undo':
                this.undoStateSegmentation();
                break;
            case 'Redo':
                this.redoStateSegmentation();
                break;
            case 'ChangeVisibiliy':
                this.hideOrShowActiveSegment(this.activeSegmentIndex);
                break;
            default:
                break;
        }
        cornerstone.updateImage(this.activeElement);
    }

    isSegmentExist(segmentName: string): boolean {
        return this.getSegmentLabelMap().has(segmentName);
    }

    activateSegmentForSelectedCell(
        label: string,
        segmentOpacity: number,
        viewportIndex = this.activeElementIndex,
        functionId = _uuid()
    ): any {
        if (!label) return;
        if (_isEmpty(viewportIndex))
            return this.elementMap.forEach((_, key) => this.activateSegmentForSelectedCell(label, segmentOpacity, key));

        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element))
            return this.elementWaitedMethods.set(functionId, {
                name: 'activateSegmentForSelectedCell',
                args: [label, segmentOpacity, viewportIndex, functionId],
            });

        const segmentLabelMap = this.getSegmentLabelMap(element).get(label);

        this.setters.activeLabelmapIndex(element, segmentLabelMap?.labelmapIndex);
        this.setters.activeSegmentIndex(element, segmentLabelMap?.segmentIndex);

        this.activeSegmentIndex = segmentLabelMap?.segmentIndex;

        this.setActiveSegmentVisible(segmentOpacity);

        this.forceRender();

        if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);
    }

    getSegmentIndex(segmentName: string) {
        const segment = this.getSegmentLabelMap().get(segmentName);
        if (!segment) return {};

        const { id, labelmapIndex, segmentIndex } = segment;
        return { id, labelmapIndex, segmentIndex };
    }

    clearSegment(segmentName: string) {
        this.elementMap.forEach(element => {
            if (!this.isElementEnabled(element)) return;

            const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
            if (!labelmaps3D) return;

            labelmaps3D.forEach((labelmap3D, i) => {
                if (!labelmap3D) return;
                const metadata = labelmap3D?.metadata ?? null;
                _compact(metadata).forEach(meta => {
                    if (meta.SegmentLabel !== segmentName) return;
                    const labelmapIndex = i;
                    const segmentIndex = meta.SegmentNumber;

                    this.clearSegmentationFromLabelmap(element, labelmapIndex, segmentIndex);
                    cornerstone.updateImage(element);
                });
            });
        });
    }

    clearToolsState() {
        const toolStateManager = this.globalToolStateManager;

        _keys(toolStateManager.toolState).forEach((imageId: string) => {
            toolStateManager.clearImageIdToolState?.(imageId);
        });

        toolStateManager.saveToolState();

        this.elementWaitedMethods.clear();
        this.resetBrushToolState();
        this.appliedImages.clear();
        this.resetReferenceLines();

        this.forceRender();
    }

    clearToolsStateForImage(imageId: string) {
        const toolStateManager = this.globalToolStateManager;
        const imageUrl = _keys(toolStateManager.toolState).find(
            (id: string) => AnnotationManagement.getImageIdFromUrl(id) === imageId
        );
        toolStateManager.clearImageIdToolState?.(imageUrl);
        toolStateManager.saveToolState();
        this.appliedImages.delete(imageId);
        this.resetReferenceLines();

        this.resetBrushToolStateByImageId(imageId);
        this.forceRender();
    }

    resetViewport(viewportIndex?: number) {
        if (_isEmpty(viewportIndex)) this.elementMap.forEach((_, key) => this.resetViewport(key));
        if (!this.isElementEnabled(viewportIndex)) return;
        cornerstone?.reset(this.getViewportElement(viewportIndex));
        this.elementWaitedMethods.clear();
        this.pendingAnnotations.clear();

        this.clearToolsState();
    }

    getAllSegmentations() {
        const result: Array<SavedSegmentation> = [];

        this.elementMap.forEach(element => {
            if (!this.isElementEnabled(element)) return;
            const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);

            labelmaps3D?.forEach((labelmap3D, i) => {
                this.getSegmentLabelMap(element).forEach(({ segmentIndex, name }) => {
                    if (segmentIndex === 0) return;

                    labelmap3D?.labelmaps2D?.forEach((labelmap2D, imageIndex) => {
                        if (!labelmap2D?.segmentsOnLabelmap?.includes(segmentIndex)) return;
                        const imageId = AnnotationManagement.getImageIdFromUrl(this.findImageId(imageIndex, element));

                        const image = this.getEnabledElement(element).image;
                        const shape = [image.height, image.width];
                        const pixelSpacing = [image.rowPixelSpacing || 1, image.columnPixelSpacing || 1];
                        let area = 0;
                        let mask = '';

                        if (labelmap2D) {
                            const segmentPixels = this.filterLabelPixels(labelmap2D.pixelData, segmentIndex);
                            mask = this.maskToRle(segmentPixels);

                            area = this.calculateSegmentArea(segmentPixels, segmentIndex, pixelSpacing);
                        }

                        const seriesId = this.getLabelmapKeyFromIndex(i);
                        const instanceId = this.getInstanceIdFromMetadata(image);

                        result.push({ mask, label: name, name, area, imageId, shape, seriesId, instanceId });
                    });
                });
            }, []);
        });

        return result;
    }

    get allSegmentations() {
        return this.getAllSegmentations();
    }

    appliedImages = new Set<string>();

    applySegmentations(args: IApplyMaskAnnotationsArgs, functionId = _uuid()): any {
        const addToStash = () =>
            this.elementWaitedMethods.set(functionId, {
                name: 'applySegmentations',
                args: [args, functionId],
            });
        try {
            const { imageId, maskList, viewportIndex, labelMapIndex, shape, seriesId, maskListKey } = args;
            if (!maskList?.length || this.appliedImages?.has(maskListKey || imageId)) return;

            if (!this.elementMap.size) return addToStash();

            const viewportList = _isEmpty(viewportIndex) ? this.elementList : [this.getViewportElement(viewportIndex)];

            for (const element of viewportList) {
                if (!this.isElementEnabled(element)) {
                    addToStash();
                    continue;
                }

                const { labelmaps3D, activeLabelmapIndex }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
                const currentLabelMapIndex = labelMapIndex ?? activeLabelmapIndex;
                const labelmap3D = labelmaps3D?.[currentLabelMapIndex];

                const imageIdIndex = this.getImageIndexFromStack(imageId, element);
                if (imageIdIndex === -1 || !labelmap3D || element?.dataset?.seriesId !== seriesId) {
                    addToStash();
                    continue;
                }

                this.applySegmentation({ currentLabelMapIndex, element, imageIdIndex, labelmap3D, maskList, shape });
                this.appliedImages.add(maskListKey || imageId);
                if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);

                cornerstone?.updateImage(element);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private applySegmentation({
        maskList,
        shape,
        currentLabelMapIndex,
        labelmap3D,
        element,
        imageIdIndex,
    }: IApplyLabelMap2DMask) {
        const image = this.getEnabledElement(element)?.image;

        const length = shape?.[0] * shape?.[1] || image.width * image.height;

        const bufferList = maskList.map(({ label, mask }) => {
            const segmentIndex = labelmap3D?.metadata?.find(meta => meta?.SegmentLabel === label)?.SegmentNumber;
            return this.rleToMask(mask, length, segmentIndex);
        });

        const mask = this.mergePixelDataLabels(bufferList, length);

        let labelmap2D = labelmap3D?.labelmaps2D?.[imageIdIndex];

        if (!labelmap2D)
            labelmap2D = this.addLabelmap2D(element, currentLabelMapIndex, imageIdIndex, image?.columns * image?.rows);

        labelmap2D.pixelData = mask;

        this.setters.updateSegmentsOnLabelmap2D(labelmap2D);
    }

    changeBrushRadiusSize(size: number) {
        this.changeBrushRadius(size);
        cornerstone.updateImage(this.activeElement);
    }

    updateBrushToolConfiguration<T extends keyof BrushToolConfig>(
        configName: T,
        value: BrushToolConfig[T],
        toolName: 'Brush' | 'BrushEraser' = 'Brush'
    ) {
        this.configuration[toolName] = { ...this.configuration[toolName], [configName]: value };
    }

    updateThresholdBrushToolConfig<T extends keyof ThresholdToolConfig>(configName: T, value: ThresholdToolConfig[T]) {
        this.configuration[configName] = value;
    }

    changeSegmentColor(id: any, color: any) {
        this.changeActiveSegmentColor(id, color);
    }

    changeSegmentVisibilityWithId(id: string, visible?: boolean) {
        this.hideOrShowActiveSegmentById(id, visible);
    }

    changeSegmentOpacity(value: number) {
        this.changeActiveSegmentOpacity(value);

        this.forceRender();
    }

    changeAllSegmentsOpacity(value: number) {
        this.changeAllSegmentOpacity(value);

        this.forceRender();
    }

    changeSegmentBorderWidth(value: number) {
        this.changeAllSegmentBorderWidth(value)
        this.forceRender();
    }

    assistedSliceDataCache = new Map<string, IRectangleRoiMeasurementData>();
    drawAssistedSliceRectangle(id: string, points: Array<IPoint>, imageId: string) {
        const data: IRectangleRoiMeasurementData = {
            uuid: id,
            visible: true,
            color: 'greenyellow',
            handles: {
                start: {
                    x: points[0].x,
                    y: points[0].y,
                    highlight: true,
                    active: true,
                },
                end: {
                    x: points[1].x,
                    y: points[1].y,
                    highlight: true,
                    active: true,
                },
            },
            type: 'AssistedSliceSelectionCross',
            isGuide: true,
            imageId,
        };

        this.assistedSliceDataCache.set(id, data);
        cornerstoneTools?.addTool(AssistedSliceSelectionCrossTool);

        this.addMeasurementToCornerstone('AssistedSliceSelectionCross', data);
    }

    clearAssistedSliceRectangle(functionId = _uuid()) {
        const isElementEnabled = this.isElementEnabled();

        if (!isElementEnabled) {
            this.elementWaitedMethods.set(functionId, { name: 'clearAssistedSliceRectangle', args: [functionId] });
            return;
        }

        _values(cornerstoneTools.globalImageIdSpecificToolStateManager.toolState).forEach(toolState => {
            if (!toolState?.AssistedSliceSelectionCross) return;
            toolState.AssistedSliceSelectionCross.data = [];
        });

        this.forceRender(this.activeElementIndex);

        if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);
    }
}

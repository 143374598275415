import { _entries, _isEmpty, getImageFormat } from 'common/Utils';
import { _mapKeys } from 'common/Utils/LangUtils/_mapKeys';
import { IDataset, IImage, TClassMapping } from 'common/entities';
import { getPlatformImageURL } from 'common/services';
import { Image } from 'cornerstone-core';
import { cornerstone, cornerstoneTools, dicomParser } from '../CornerstoneInitHelper/CornerstoneHelper';
import initCornerstone from '../CornerstoneInitHelper/initCornerstone';
import freehandSegmentationMixin from '../CustomTools/Mixins/freehandSegmentationMixin';
import polylineSegmentationMixin from '../CustomTools/Mixins/polylineSegmentationMixin';
import { TAG_DICT } from './DicomTagDict';
import { DicomViewerControlTool } from './interface';

export abstract class DicomViewerCore {
    classMapping: TClassMapping;
    colorMap: Dictionary<string>;
    elementMap: Map<number, HTMLDivElement> = new Map();
    imageMap: Map<number, Image> = new Map();
    activeElementIndex = 0;
    activeTool: DicomViewerControlTool = 'StackScroll';
    currentImageIdIndex: number;
    dataset: IDataset;

    ready = false;
    constructor() {
        initCornerstone();

        cornerstoneTools.register('mixin', 'freehandSegmentationMixin', freehandSegmentationMixin, true);
        cornerstoneTools.register('mixin', 'polylineSegmentationMixin', polylineSegmentationMixin, true);
    }

    public onReady() {
        this.ready = true;
    }

    public destroy() {
        this.clearToolsState();
    }

    abstract clearToolsState(): void;

    public getImageFromViewportElement(element: HTMLDivElement) {
        return cornerstone.getImage(element);
    }

    public static getImageIdFromUrl(url: string) {
        if (!url) return '';
        return new URL(url).searchParams.get('image_id');
    }

    public saveImage(imageName: string) {
        cornerstoneTools.SaveAs(this.activeElement, imageName, 'image/png');
    }

    get activeElement() {
        return this.elementMap.get(this.activeElementIndex);
    }
    getViewportElement(index: number = this.activeElementIndex) {
        return this.elementMap.get(index);
    }

    public getDicomTagsWithViewport(index = this.activeElementIndex) {
        const image = this.imageMap.get(index);
        if (!image) return {};
    }

    public getDicomTags(image: any): Partial<Record<(typeof TAG_DICT)[keyof typeof TAG_DICT], any>> {
        if (!image?.data) return {};
        const rawTags: any = dicomParser.explicitDataSetToJS(image.data, { omitPrivateAttibutes: true } as any);

        const flattenedTags = this.flattenTags(rawTags);

        return _mapKeys(flattenedTags, (tag: string) => {
            const group = tag.substring(1, 5);
            const element = tag.substring(5, 9);
            const tagIndex = ('(' + group + ',' + element + ')').toUpperCase() as keyof typeof TAG_DICT;
            return TAG_DICT[tagIndex];
        }) as any;
    }

    public flattenTags(tags: Dictionary<any>) {
        return _entries(tags).reduce((acc: Dictionary<string>, [key, value]: [string, any]) => {
            if (typeof value === 'string') acc[key] = value;

            if (Array.isArray(value)) {
                value.forEach(item => {
                    Object.assign(acc, this.flattenTags(item));
                });
            }

            return acc;
        }, {});
    }

    toggleInterpolation(value?: boolean) {
        this.elementMap.forEach(element => {
            if (!this.isElementEnabled(element)) return;
            const viewport = cornerstone.getViewport(element);
            viewport.pixelReplication = !(value ?? viewport.pixelReplication);
            cornerstone.setViewport(element, viewport);
        });
    }

    getEnabledElement(element: HTMLElement) {
        if (!element) return null;
        return this.enabledElements.find(enabled => enabled?.element === element);
    }

    isElementEnabled(_element: number | HTMLElement = this.activeElementIndex) {
        try {
            const element = typeof _element === 'number' ? this.getViewportElement(_element) : _element;
            if (!element) return false;
            const enabledElement = this.getEnabledElement(element);
            if (enabledElement) return true;
            return false;
        } catch (error) {
            return false;
        }
    }

    forceRender(viewportIndex?: number) {
        if (_isEmpty(viewportIndex))
            this.elementMap.forEach((_, index) => {
                this.forceRender(index);
            });

        const element = this.getViewportElement(viewportIndex);

        const enabledElement = this.getEnabledElement(element);
        if (!enabledElement) return;

        cornerstone.drawImage(enabledElement, true);
    }

    getImageIndexFromStack(imageId: string, element = this.activeElement): number {
        const stack = cornerstoneTools.getToolState(element, 'stack')?.data?.[0];

        return stack?.imageIds?.findIndex((id: string) => DicomViewerCore.getImageIdFromUrl(id) === imageId);
    }

    get enabledElements() {
        return cornerstone.getEnabledElements();
    }

    get elementList() {
        return [...this.elementMap.values()];
    }

    static readonly DefaultTool = 'StackScroll' as const;

    static readonly toollist = [
        // Mouse
        {
            name: 'StackScroll',
            mode: 'active',
            modeOptions: { mouseButtonMask: 1 },
        },
        {
            name: 'Zoom',
            mode: 'active',
            modeOptions: { mouseButtonMask: 2 },
        },
        {
            name: 'Pan',
            mode: 'active',
            modeOptions: { mouseButtonMask: 4 },
        },
        'Bidirectional',
        'FreehandRoi',
        'Eraser',
        'EllipticalRoi',
        'Wwwc',
        'DragProbe',
        {
            name: 'ScaleOverlay',
            modeOptions: { mouseButtonMask: 1 },
        },
        // Scroll
        { name: 'StackScrollMouseWheel', mode: 'active' },
        // Touch
        { name: 'PanMultiTouch', mode: 'active' },
        { name: 'ZoomTouchPinch', mode: 'active' },
        { name: 'StackScrollMultiTouch', mode: 'active' },
        { name: 'ReferenceLines', mode: 'active' },
    ];

    static readonly playgroundToolList = [...this.toollist, 'Length', 'Angle', 'RectangleRoi'];

    static getImageUrl(dataset: IDataset, image: IImage) {
        const currentImageFormat = getImageFormat(dataset, image);

        const url = getPlatformImageURL({
            imageId: image?.imageId,
            datasetId: dataset?.datasetId,
            rawImage: true,
            return_handler: currentImageFormat === 'dicom' || currentImageFormat === 'nifti',
        });

        return this.getLoaderPrefix(currentImageFormat) + url;
    }

    static getLoaderPrefix(encoding: 'dicom' | 'nifti' | 'png' | 'jpeg') {
        switch (encoding) {
            case 'dicom':
                return 'wadouri:';

            case 'nifti':
                return 'nifti:';

            default:
                return `${window.location.origin}`;
        }
    }
}

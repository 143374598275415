export interface IUserManagement {
    newUserModal: boolean;
    userOptionsModal: boolean;
    selectedUser: string;
}

export function UserManagementFactory(data?: Partial<IUserManagement>): IUserManagement {
    return {
        newUserModal: data?.newUserModal || false,
        userOptionsModal: data?.userOptionsModal || false,
        selectedUser: data?.selectedUser || '',
    };
}

export interface IVerificationItem {
    type: VerificationType;
    email?: string;
    domain?: string;
    _id?: string;
}

export interface IUser {
    _id: string;
    email: string;
    createDate: string;
    status: string;
    role: 'admin' | 'user';
    displayName?: string;
}

export type VerificationType = 'user' | 'domain';

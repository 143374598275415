export interface IObjectComparisonState {
    label: string;
    userList: Array<string>;
    studyId: string;
    seriesId: string;
    imageIdIndex: number;
    labelModalOpen: boolean;
    colorMap: Record<string, string>;
    userVisibilityMap: Record<string, boolean>;
}

export function ObjectComparisonFactory(data?: Partial<IObjectComparisonState>): IObjectComparisonState {
    return {
        label: data?.label ?? '',
        userList: data?.userList ?? [],
        studyId: data?.studyId ?? '',
        seriesId: data?.seriesId ?? '',
        imageIdIndex: data?.imageIdIndex ?? null,
        labelModalOpen: data?.labelModalOpen ?? false,
        colorMap: data?.colorMap ?? {},
        userVisibilityMap: data?.userVisibilityMap ?? {},
    };
}
